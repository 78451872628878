import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

const NotFoundPage: React.FC = () => {
  return (
    <main>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <h1>Page not found</h1>
      <p>
        Sorry{' '}
        <span role='img' aria-label='Pensive emoji'>
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
        <Link to='/'>Go home</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;
